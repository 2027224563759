.site-layout .site-layout-background {
    background: #fff;
    width:100%;
}

.filtros {
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    flex-direction: row;
}

.modal {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}

.login {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
}


.ant-table-thead .ant-table-cell{
    background: #061678;
    color: #9b9c9e
}

th.ant-table-column-sort {
    background: #061678;
    color: #9b9c9e
}

th.ant-table-column-has-sorters {
    background: #061678;
}

th.ant-table-column-has-sorters::before{
    background: #061678;
}

thead:hover {
    background: #2D3144 !important;
}


.ant-btn-primary {
    background-color: #475BD8;
    border-color: #475BD8;;
}

.ant-menu{
    background: #475BD8;
}
  
