.site-layout .site-layout-background {
    background: #fff;
}

.filtros {
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    flex-direction: row;
}

.modal {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}

.truncate {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.textarea_custom {
    width: 300px; 
}