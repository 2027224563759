.site-layout .site-layout-background {
    background: #fff;
    white-space:nowrap;
    width:100%;
}

.filtros{
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    flex-direction: row;
}

.modal {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}
