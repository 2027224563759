#components-layout-demo-side{
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.logo{
    height: 32px;
    margin: 16px;
    display: flex;
    justify-content: center;
}

.site-layout .site-layout-background {
    background: #fff;
}
